import axios from 'axios';
import React from 'react';
import Header from '../../components/Header';
import Loader from '../../components/Loader/Loader';
import { BASE_URL, PATH } from '../../constants/variables';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import StaticFooter from '../../components/StaticFooter/StaticFooter';
import {
  AdvancedHeadIntro,
  RoleMatching,
  TeamFitDiversity,
  Cover,
  PrintFooter,
  StrengthBlock,
  CareerDriversBlock,
  AssessmentSumProgressBlock,
  AssessmentSumChart,
  ResponsesPageBlock,
} from '../../components/Report';
import { RouteComponentProps } from 'react-router-dom';
import { Scope, TranslateOptions } from 'i18n-js';
import { Answer } from '../../models/answers';
import { CareerDriver } from '../../models/careerDrivers';
import { User } from '../../models/user';
import { Application, Job, Trait, TraitScore } from '../../models/applications';
import { QuestionType } from '../../models/assessments';
import queryString from 'query-string';
import setToken from '../../setToken';

interface AdvanceManagerReportProps
  extends RouteComponentProps<{ id?: string }> {
  t: (scope: Scope, options?: TranslateOptions) => string;
}

interface ICombineData {
  score: number;
  name: string;
  isAssessed: boolean;
}

interface AdvanceManagerReportState {
  traits: Trait[];
  traitScores: TraitScore[];
  leastImportantCareerDrivers: CareerDriver[];
  mostImportantCareerDrivers: CareerDriver[];
  loading: boolean;
  saving: boolean;
  me: User | null;
  appliedDate: null | Application['applied'];
  derailer_traits: Trait[];
  matchedTraits: Trait[];
  combineData: ICombineData[];
  greenEmployee: User[];
  redEmployee: User[];
  candidates: User | null;
  fitScore: number;
  job: Job | null;
  reportLogo: string | undefined;
  profileAnswers: Answer[];
  technicalAnswers: Answer[];
  surveyAnswers: Answer[];
}

class AdvanceManagerReport extends React.Component<
  AdvanceManagerReportProps,
  AdvanceManagerReportState
> {
  state: AdvanceManagerReportState = {
    traits: [],
    traitScores: [],
    leastImportantCareerDrivers: [],
    mostImportantCareerDrivers: [],
    combineData: [],
    me: null,
    greenEmployee: [],
    redEmployee: [],
    loading: false,
    saving: false,
    candidates: null,
    fitScore: 0,
    derailer_traits: [],
    matchedTraits: [],
    appliedDate: '',
    reportLogo: undefined,
    job: null,
    profileAnswers: [],
    technicalAnswers: [],
    surveyAnswers: [],
  };

  applied: Application['applied'] | null = null;

  companyData: Application['job']['company'] | any = [];

  componentDidMount() {
    let token = localStorage.getItem('token');
    if (!token) {
      // @ts-ignore
      ({ token } = queryString.parse(this.props.location.search));
    }
    if (token) {
      setToken(token);
    }
    const appId = this.props.match.params.id;

    axios.get(`${BASE_URL}${PATH}applications/${appId}/`).then((res) => {
      const traitScores: TraitScore[] = res.data.trait_scores;
      const traits: Trait[] = res.data.traits;
      const candidateId = res.data.candidate_id;
      const responseData = res;
      this.applied = res.data.applied;
      this.companyData = res.data.job.company;
      const combineData: ICombineData[] = [];
      this.setState({
        reportLogo: this.companyData?.custom_report?.custom_report_logo,
      });

      combineData.push({
        score: res.data.personality_trait_score,
        name: 'TR8S© Personality',
        isAssessed:
          res.data.personality_trait_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.Personality &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.remote_work_score,
        name: 'Remote Work',
        isAssessed:
          res.data.remote_work_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.Personality &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.game_score,
        name: 'Game-based Assessment',
        isAssessed:
          res.data.game_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.Games &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.video_score,
        name: 'Video Assessment',
        isAssessed:
          res.data.video_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.VideoAssessment &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.sjt_score,
        name: 'Situational Judgment',
        isAssessed:
          res.data.sjt_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.SituationalJudgement &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.technical_score,
        name: 'Technical Assessment',
        isAssessed:
          res.data.technical_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.TechnicalAssessment &&
              assessment.answers_submitted
            );
          }),
      });
      combineData.push({
        score: res.data.team_fit_score,
        name: 'Team Fit',
        isAssessed:
          res.data.team_fit_score >= 0 &&
          res.data.assessments.find((assessment: any) => {
            return (
              assessment.test.type.id === QuestionType.Personality &&
              assessment.answers_submitted
            );
          }),
      });

      axios
        .all([
          axios.get(
            `${BASE_URL}${PATH}applications/${appId}/least_important_drivers/`,
          ),
          axios.get(
            `${BASE_URL}${PATH}applications/${appId}/most_important_drivers/`,
          ),
          axios.get(`${BASE_URL}/api/auth/candidates/${candidateId}`),
        ])
        .then((response) => {
          this.setState({
            traits: traits,
            traitScores: traitScores,
            leastImportantCareerDrivers: response[0].data,
            mostImportantCareerDrivers: response[1].data,
            greenEmployee: responseData.data.green_employees,
            redEmployee: responseData.data.red_employees,
            me: response[2].data,
            derailer_traits: responseData.data.derailer_traits,
            matchedTraits: responseData.data.matched_traits,
            combineData: combineData,
            candidates: response[2].data,
            fitScore: responseData.data.fit_score,
            appliedDate: responseData.data.applied,
            job: responseData.data.job,
            profileAnswers: responseData.data.profile_answers,
            technicalAnswers: responseData.data.technical_answers,
            surveyAnswers: responseData.data.survey_answers,
          });
        });
    });
  }

  print() {
    window.print();
  }

  render() {
    const {
      greenEmployee,
      redEmployee,
      me,
      job,
      traits,
      traitScores,
      candidates,
      combineData,
      fitScore,
      matchedTraits,
      derailer_traits,
      mostImportantCareerDrivers,
      leastImportantCareerDrivers,
      profileAnswers,
      technicalAnswers,
      surveyAnswers,
    } = this.state;
    const { t } = this.props;

    const name = this.state.me
      ? `${this.state.me.first_name} ${this.state.me.last_name}`
      : null;

    if (
      !traitScores?.length ||
      this.state.loading ||
      !me ||
      !job ||
      !candidates
    ) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    const customReport = this.companyData.custom_report
      ? this.companyData.custom_report
      : false;
    const hasCustomTraits = traitScores.filter(
      (trait) => trait.trait.is_custom,
    );

    return (
      <div
        id="pdf-root"
        className={`container report text-align-start ${
          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
        }`}
        style={
          this.state.saving
            ? {
                maxWidth: 1024,
              }
            : {}
        }
      >
        <Header
          // @ts-ignore
          companylogo={
            this.state.reportLogo
              ? this.state.reportLogo
              : '/assets/img/logo.png'
          }
          isDashboardHeader
        />
        <Cover
          name={name}
          applied={this.applied}
          title={t('Advanced Manager Report')}
          showConfidentialityNote={true}
        />

        <div className="page-break"></div>

        <AdvancedHeadIntro
          name={me.first_name + ' ' + me.last_name}
          data={combineData}
          fit_scoreData={fitScore}
          first_name={me.first_name}
          job={job}
        />

        <StrengthBlock
          data={traitScores}
          companyData={this.companyData}
          isLineManager={true}
        />

        <RoleMatching
          potentialDerailers={derailer_traits}
          matchedTraits={matchedTraits}
          name={me.first_name.trimEnd()}
          candidateData={candidates}
          job={job}
        />

        <AssessmentSumChart
          scores={traitScores}
          traits={traits}
          showMappingLegend={
            hasCustomTraits &&
            customReport &&
            customReport.show_traits_mapping_portal
          }
        />

        <AssessmentSumProgressBlock
          scores={traitScores}
          isAppendixPage={false}
        />

        {(!!greenEmployee.length || !!redEmployee.length) && (
          <TeamFitDiversity
            redEmployeeData={redEmployee}
            greenEmployeeData={greenEmployee}
            candidateData={candidates}
          />
        )}
        <CareerDriversBlock
          name={me.first_name}
          dataMost={mostImportantCareerDrivers}
          dataLeast={leastImportantCareerDrivers}
        />

        {customReport &&
        customReport.portal_advanced_report &&
        customReport.show_profile_answers_portal &&
        profileAnswers.length ? (
          <ResponsesPageBlock
            name={me.first_name}
            pageName={t('Profile')}
            answers={profileAnswers}
          />
        ) : (
          <></>
        )}

        {customReport &&
        customReport.portal_advanced_report &&
        customReport.show_technical_answers_portal &&
        technicalAnswers.length ? (
          <ResponsesPageBlock
            name={me.first_name}
            pageName={t('Technical Assessment')}
            answers={technicalAnswers}
          />
        ) : (
          <></>
        )}

        {customReport &&
        customReport.portal_advanced_report &&
        customReport.show_survey_answers_portal &&
        surveyAnswers.length ? (
          <ResponsesPageBlock
            name={me.first_name}
            pageName={t('Pulse Survey')}
            answers={surveyAnswers}
          />
        ) : (
          <></>
        )}

        {hasCustomTraits &&
          customReport &&
          customReport.show_traits_appendix_portal &&
          traitScores.find((item) => item.trait.group_id === null) && (
            <AssessmentSumProgressBlock
              scores={traitScores}
              isAppendixPage={true}
            />
          )}

        <section className="row justify-content-center candidate-report">
          {!this.state.saving && (
            <button
              type="button"
              onClick={this.print}
              className="print-button test-result-block-button theme-bg"
            >
              <i className="fas fa-download"></i> {t('Download')}
            </button>
          )}
        </section>

        <StaticFooter />
        <PrintFooter name={name} />
      </div>
    );
  }
}

export default withTranslation()(AdvanceManagerReport);
